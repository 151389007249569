export const STRIPE_PRE_PAY = 'cdb/payment/stripe/prepay' // 原先的预授权租借
export const STRIPE_RECHARGE = 'cdb/payment/stripe/recharge' // 原先的订单付款
export const STRIPE_PREPAY_SPTOKEN = 'cdb/payment/stripe/multicurrency/preAuth/stripToken' // 目前使用的预授权租借
export const STRIPE_PAYORDER_SPTOKEN = 'cdb/payment/stripe/multicurrency/payOrder/stripToken' // 目前使用的订单付款
export const STRIPE_PAYORDER = 'cdb/payment/stripe/multicurrency/payOrder' // 信用卡订单付款
export const STRIPE_RECHARGE_WALLET = 'cdb/payment/stripe/multicurrency/rechargeWallet' // 创建钱包充值订单

export const STRIPE_PREAUTH_OF_CONFIRM = 'cdb/payment/stripe/multicurrency/preauthOfIntent/payment' // 信用卡预授权租借
export const STRIPE_CLIENTSECRET = 'cdb/payment/stripe/multicurrency/clientSecret' // 获取客户秘钥
export const STRIPE_PREAUTH_AUTO_CONFIRM1 = 'cdb/payment/stripe/authConfirm'
export const STRIPE_PREAUTH_AUTO_CONFIRM = 'cdb/payment/stripe/multicurrency/confirm' // 信用卡确认订单
export const STRIPE_PAYCONFIRM = 'cdb/payment/stripe/multicurrency/payConfirm/payment' // 确认订单-旧

export const STRIPE_SAVE_CARD = 'cdb/payment/stripe/multicurrency/saveCardOfIntent' // stripe保存卡
export const STRIPE_SAVE_CARD_CONFIRM = 'cdb/payment/stripe/multicurrency/saveCardConfirm' // stripe保存卡确认
export const SAVED_CARD_RENT = 'cdb/payment/stripe/multicurrency/preauthByCard/payment' // 已保存卡租借
export const SAVED_CARD_TO_RENT = 'cdb/cabinet/savedCardToRent'
export const SAVE_CARD_SETUP_TOKEN = 'cdb/payment/paypal/multicurrency/setupToken' // paypal保存卡
export const SAVE_CARD_SAVE_TOKEN = 'cdb/payment/paypal/multicurrency/saveToken' // paypal保存卡确认
export const PAYPAL_PAYORDER = 'cdb/payment/paypal/multicurrency/payOrder'
export const PAYPAL_PAYORDER_COMFIRM = 'cdb/payment/paypal/multicurrency/payment/confirm'

export const PAYPAL_PRE_PAY = 'cdb/payment/paypal/prepay'
export const PAYPAL_RECHARGE = 'cdb/payment/paypal/recharge'
export const PAYPAL_EXC_PAY = 'cdb/payment/paypal/excpay'
export const PAYPAL_PREAUTH = "cdb/payment/paypal/multicurrency/preauth"; // paypal创建预授权订单
export const PAYPAL_CONFIRM = "cdb/payment/paypal/multicurrency/authConfirm"; // paypal确认订单

export const PAYMODE = 'cdb/cabinet/check/payment' // 获取支付方式列表
// MopepePayment
export const MOPEPE_RECHARGE = 'cdb/payment/mopepe/multiCurrency/recharge' // 钱包充值或租借
export const MOPEPE_PAY = 'cdb/payment/mopepe/multiCurrency/payOrder' // 订单付款
export const MOPEPE_CONFIRM = 'cdb/payment/mopepe/multiCurrency/payment/confirm' // 确认订单

// midtrans
export const MIDTRANS_RECHARGE = 'cdb/payment/midtrans/multicurrency/recharge' // 押金充值
export const MIDTRANS_PAYORDER = 'cdb/payment/midtrans/multicurrency/payOrder' // 订单付款
export const MIDTRANS_VIPORDER = 'cdb/payment/midtrans/multicurrency/vipOrder' // 购买vip
export const MIDTRANS_PAYCONFIRM = 'cdb/payment/midtrans/multicurrency/payment/confirm' // 确认订单

// 肯尼亚支付
export const MPESA_DEPOSIT_RENT = 'cdb/cabinet/useDepositToRent' // 押金租借
export const MPESA_RECHARGE = 'cdb/payment/m-pesa/multiCurrency/recharge' // 钱包充值
export const MPESA_PAYORDER = 'cdb/payment/m-pesa/multiCurrency/payOrder' // 订单付款
export const MPESA_VIPORDER = 'cdb/payment/m-pesa/multiCurrency/vipOrder' // 购买vip
export const MPESA_PAYCONFIRM = 'cdb/payment/m-pesa/multiCurrency/payment/confirm' // 确认订单
export const SAVE_CARD = 'cdb/payment/quickpay/multiCurrency/saveCard' // 保存卡
// export const PAYMENT_METHOD_LIST = 'cdb/cabinet/check/payment' // 支付通道配置列表
