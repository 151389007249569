export const USER_CAPTCHA = 'captcha.webp'
export const LOGIN_CODE = 'saas/mobileLogin'
export const LOGIN_EMAIL = 'saas/emailLogin'
export const SEND_CODE = 'saas/sendVerifyCodeSms'
export const SEND_MAIL = 'saas/sendVerifyCodeMail'
export const USER_INFO = 'userInfo'
export const USER_WALLET = 'cdb/user/wallet'

export const LOGIN_ALIPAY = 'loginAlipayByCode'
export const LOGIN_WECHAT = 'loginWechatByUserInfo'
export const LOGIN_FACEBOOK = 'saas/loginFacebookByToken'
export const LOGIN_GOOGLE = 'saas/loginGoogleByCode'

export const JS_CONFIG = 'cdb/setting/js_config'

export const ORDER_LIST = 'cdb/mine/order/list'
export const REFUND_APPLY = 'cdb/mine/applyForRefund/multiCurrency/new'// 提现

export const HELP_LIST = 'cdb/mine/help/list'

export const TX_APPLY = 'cdb/mine/tixian/apply'
export const TX_POST = 'cdb/mine/tixian/create'

export const USER_CARD = 'cdb/user/card' // 卡列表
export const WALLET_LIST = 'cdb/user/wallet' // 钱包列表
export const REMOVE_CARD = 'cdb/user/card/remove/' // 删除卡
