<template>
  <page :title="i18n.title">
    <div class="confirm-warp">
      <div class="confirm-info">
        <div class="confirm-title">{{ i18n.pay_label }}</div>
        <div class="confirm-price">
          {{ currency }}{{ payMode === "creditcard" ? cabinet.yajin : price }}
        </div>
        <!-- <div class="confirm-icon">
            <img src="../../assets/imgs/icon_tips.png" />
            <span>{{ payType === 'YJ' ? i18n.price_tips : i18n.auth_tips.format(currency, price) }}</span>
          </div> -->
      </div>
      <div class="confirm-payment-label">
        <span>{{ i18n.pay_type }}</span>
      </div>
      <div class="confirm-payment">
        <!-- <div class="confirm-payment-item" @click="payMode='paypal'">
            <div class="confirm-payment-item-info">
              <img src="../../assets/imgs/icon_paypal.png" />
              <div class="content">
                <div class="title">Paypal</div>
              </div>
            </div>
            <div class="confirm-payment-item-checked">
              <img v-if="payMode==='paypal'" src="../../assets/imgs/checkbox_selected.png" />
            </div>
          </div> -->
        <template v-for="item in payModeList">
          <div
            :key="item.pId"
            v-if="
              item.phone === 'all' ||
              item.phone === system ||
              item.phone === browser ||
              (item.phone === 'phone' && system !== '')
            "
          >
            <div
              class="confirm-payment-item"
              :class="{
                bordermaincolor:
                  payMode === item.pPaymentType && item.pId === pId
              }"
              @click="onPayMode(item)"
            >
              <div class="confirm-payment-item-info">
                <img
                  v-if="
                    item.pPaymentType === 'GOOGLE_PAY' ||
                    item.pPaymentType === 'STRIPE_GOOGLE'
                  "
                  :src="require('../../assets/imgs/icon_googlepay.png')"
                />
                <img
                  v-else-if="
                    item.pPaymentType === 'APPLE_PAY' ||
                    item.pPaymentType === 'STRIPE_APPLE'
                  "
                  :src="require('../../assets/imgs/icon_applepay.png')"
                />
                <img
                  v-else-if="item.pPaymentType === 'PAYPAL'"
                  :src="require('../../assets/imgs/icon_paypal.png')"
                />
                <img
                  v-else
                  :src="require('../../assets/imgs/icon_creditcard.png')"
                />
                <div class="content">
                  <div class="title">{{ item.label }}</div>
                </div>
              </div>
              <div class="confirm-payment-item-checked">
                <img
                  v-if="payMode === item.pPaymentType && item.pId === pId"
                  src="../../assets/imgs/checkbox_selected.png"
                />
              </div>
            </div>
            <div v-if="item.paymentConfig" class="confirm-payment-item-operate">
              <span
                class="confirm-payment-item-operate-text"
                @click="deletePayCard(item)"
                >{{ i18n.deletecard }}</span
              >
            </div>
          </div>
        </template>
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onPay" :disabled="disabled">
          {{ i18n.submit }}
        </div>
      </div>
      <popup-warp v-model="isCreditcard">
        <div>
          <div class="creditcard-input">
            <div id="credit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripeSetup">
            {{ i18n.paynow }}
          </div>
        </div>
      </popup-warp>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import {
  STRIPE_PREPAY_SPTOKEN,
  STRIPE_PAYORDER_SPTOKEN,
  PAYPAL_PREAUTH,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREAUTH_AUTO_CONFIRM,
  PAYMODE,
  STRIPE_PAYORDER,
  STRIPE_RECHARGE_WALLET,
  MIDTRANS_RECHARGE,
  MIDTRANS_PAYORDER,
  SAVED_CARD_TO_RENT,
  SAVED_CARD_RENT,
  STRIPE_SAVE_CARD,
  STRIPE_SAVE_CARD_CONFIRM
} from "../../apis/payment"
import { USER_CARD, REMOVE_CARD } from "../../apis/user.js"

import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp
  },
  computed: {
    ...mapState([
      "system",
      "browser",
      "cabinet",
      "user",
      "qrcode",
      "wallet",
      "agentOpenId"
    ]),
    i18n() {
      return this.$t("confirm")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else {
        return this.user.pcurrencyName
      }
    }
  },
  watch: {
    payMode(val, oldVal) {
      console.log("payMode", val)
      if (
        val === "APPLE_PAY" ||
        val === "STRIPE_APPLE" ||
        val === "STRIPE_GOOGLE" ||
        val === "GOOGLE_PAY"
      ) {
        if (!this.stripePayRequest) {
          this.disabled = true
          this.$loading(true)
          this.getStripeToken()
        } else {
          this.disabled = false
        }
      } else {
        this.disabled = false
      }
    },
    stripePayRequest(val, oldVal) {
      this.disabled = !val
    }
  },
  data() {
    return {
      price: 0,
      payType: "YJ",
      formId: "",
      cabinetID: "",
      yaJinType: null,
      pdailiId: "",
      payMode: "STRIPE_CARD",
      pId: "", // 代理或平台支付配置id
      currencySymbol: "$",
      country: "US", // 国家
      currencyname: "usd", // 货币类型
      publicKey: "", // 支付通道的公钥
      paymentConfig: {},
      disabled: false,
      stripePayRequest: null,
      isCreditcard: false,
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      creditcard: null,
      clientSecret: null,
      payModeList: [],
      orderId: "",
      phone: ""
    }
  },
  methods: {
    // 支付方式选中
    onPayMode(v) {
      console.log("支付方式===", v)
      this.pId = v.pId
      this.payMode = v.pPaymentType
      this.currencySymbol = v.currencySymbol
      this.currencyname = v.configDetails.currency
      this.publicKey = v.publicKey
      this.country = v.configDetails.accountCountry
      this.paymentConfig = v.paymentConfig
    },
    // 删除支付卡
    deletePayCard(v) {
      this.$post(
        `${REMOVE_CARD + v.pId}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.$toast(this.i18nMsg.success)
          this.getPayMode()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onPay() {
      console.log(`payMode-${this.payMode}`)
      if (this.payMode === "PAYPAL") {
        this.onPaypal()
      } else if (
        this.payMode === "APPLE_PAY" ||
        this.payMode === "STRIPE_APPLE" ||
        this.payMode === "STRIPE_GOOGLE" ||
        this.payMode === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (
        this.payMode === "STRIPE_CARD" ||
        this.payMode === "STRIPE_KLARNA"
      ) {
        this.onCreditcard(this.publicKey)
      } else if (
        this.payMode === "MIDTRANS_CARD" ||
        this.payMode === "MIDTRANS_GOPAY"
      ) {
        this.onMidtrans()
      } else {
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
          this.$router.replace("/result")
        }, 3000)
      }
    },
    checkOrder(tradeNo, payType, query) {
      if (payType === "CZ") {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.success_payment,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.replace("/mine")
        })
      } else {
        if (payType === "FK") {
          this.$router.replace(
            `/result?tradeNo=${tradeNo}&payMode=stripe&payType=FK`
          )
          return
        }
        this.$router.replace(`/result?tradeNo=${tradeNo}&payMode=stripe`)
      }
    },
    getStripeToken() {
      getToken(this.price, this.country, this.currencyname, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet && this.payType === "YJ") {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.pId // 代理或平台支付配置id
        }
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER_SPTOKEN
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          stripeToken: token,
          paymentId: this.pId
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onConfirmSaveCard(pid, id) {
      this.$loading(true)
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        {
          paymentId: pid,
          paymentMethod: id
        },
        (resp) => {
          this.$loading(false)
          console.log("saveCardConfirm====", resp)
          //   this.getCardList()
          this.saveToRent()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },
    saveToRent() {
      this.$loading(true)
      this.$get(
        `${SAVED_CARD_TO_RENT}`,
        {
          qrcode: this.cabinet.cabinetID || this.$route.query.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.out_trade_no) {
            this.checkOrder(resp.data.out_trade_no, this.payType)
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    // 获取用户支付卡信息
    async getUserPayCard() {
      this.$loading(true)
      const qrcode = this.qrcode || this.$route.query.cabinetID
      const { data = [] } = await this.$Request("get", USER_CARD, {
        qrcode,
        orderId: "",
        agentld: this.agentOpenId
      })
      let cardList = []
      if (data && data.length) {
        cardList = data.map((item) => {
          return {
            phone: "all",
            label: item.pCardnumber,
            ...item.paymentConfig,
            ...item
          }
        })
        this.cardList = cardList
      }
      this.$loading(false)
      return cardList
    },
    onPaypal() {
      this.$loading(true)
      let url =
        PAYPAL_PREAUTH + `/${this.qrcode || this.$route.query.cabinetID}`
      this.$post(
        url,
        {
          paymentId: this.pId
        },
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            this.$loading(true)
            this.$store.commit("paypalTradeNo", resp.data.tradeNo)
            this.$store.commit("paymentId", this.pId)
            window.location.href = resp.data.approve
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPaypal()
          })
          console.log(error)
        }
      )
    },
    onMidtrans() {
      this.$loading(true)
      let url = ""
      let datas = {}
      if (this.payType === "YJ") {
        url = `${MIDTRANS_RECHARGE}`
        datas = {
          paymentId: this.pId,
          rechargeType: "balance",
          amount: this.price,
          qrcode: this.qrcode || this.$route.query.cabinetID,
          rentNow: true
        }
      } else {
        url = MIDTRANS_PAYORDER
        datas = {
          outTradeNo: this.orderId,
          paymentId: this.pId
        }
      }
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data && resp.data.redirectUrl) {
            window.location.href = resp.data.redirectUrl
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },
    async cardToRent() {
      let url = `${SAVED_CARD_RENT}/${this.cabinet.cabinetID}`
      this.$loading(true)
      const result = await this.$Request("post", url, {
        cardId: this.pId,
        paymentId: this.paymentConfig.pId,
        amount: this.price
      })
      this.$loading(false)
      if (result.data && result.data.outTradeNo) {
        this.checkOrder(result.data.outTradeNo, this.payType)
      } else {
        this.$confirm({
          title: this.i18n.msg_fail_title,
          content: this.i18n.msg_fail_order,
          confirmText: this.i18n.msg_fail_retry,
          cancelText: this.i18n.msg_fail_cancel
        }).then(() => {
          this.cardToRent()
        })
      }
    },
    onCreditcard(publicKey) {
      // console.log('publicKey', publicKey)
      const { paymentWay } = this.$route.query
      let paymentId = this.pId
      if (this.paymentConfig && this.paymentConfig.pId) {
        paymentId = this.paymentConfig.pId
      }
      if (paymentWay === "PAYMENT_CARD") {
        return this.saveCard()
      }
      let url = ""
      let datas = {}
      if (this.cabinet && this.payType === "YJ") {
        url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.cabinetID}`
        datas = {
          paymentId: paymentId,
          amount: this.price
        }
        if (this.paymentConfig) {
          return this.cardToRent(this.paymentConfig)
        }
      } else if (this.price > 0 && this.payType === "FK") {
        url = STRIPE_PAYORDER
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          cardId: this.pId,
          paymentId: paymentId
        }
      } else {
        url = STRIPE_RECHARGE_WALLET
        datas = {
          amount: this.price,
          paymentId: paymentId
        }
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripe) {
              this.clientSecret = resp.data
              const layout = {
                layout: "tabs"
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: "stripe"
                }
              }
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create("payment", layout)
              this.isCreditcard = true
              paymentElement.mount("#credit-card-element")
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard(publicKey)
          })
          console.log(error)
        }
      )
    },

    saveCard() {
      const { paymentWay, paymentId } = this.$route.query
      console.log("this.payMode", this.payMode)
      if (!this.user) {
        this.$toast(this.i18nMsg.unlogin)
        return
      }
      if (!this.payMode || !this.pId) {
        this.$toast(this.i18nMsg.nopaymode)
        return
      }
      let url = `${STRIPE_SAVE_CARD}?paymentId=${paymentId || this.pId}`
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.creditOrder = resp.data
          if (paymentWay === "PAYMENT_CARD") {
            this.onLoadElement()
          }
          // this.onCreditcard(this.publicKey)

          console.log("saveCard====", resp)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        }
      )
    },
    onLoadElement() {
      const options = {
        clientSecret: this.creditOrder.clientSecret,
        appearance: {
          theme: "stripe"
        }
      }
      if (window.stripe) {
        // this.loadStripeScript(this.payMode.publicKey)
        this.creditcard = window.stripe.elements(options)
        const paymentElement = this.creditcard.create("payment")
        this.isCreditcard = true
        // this.payListShow = false
        paymentElement.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      const { paymentWay } = this.$route.query
      // 失败事件回调
      const failCall = (result) => {
        console.log(result)
        vm.$loading(false)
        if (result.error) {
          vm.$toast(result.error.message)
        }
      }
      if (paymentWay === "PAYMENT_CARD") {
        let url = `${window.location.origin}#/confirm?qrcode=${this.cabinet.qrcode}&paymentId=${this.pId}&pmid=${this.creditOrder.paymentId}`
        window.stripe
          .confirmSetup({
            elements: vm.creditcard,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: url
            }
          })
          .then((result) => failCall(result))
      } else {
        window.stripe
          .confirmPayment({
            elements: vm.creditcard,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: `${window.location.href}&paymentId=${this.pId}`
            }
          })
          .then((result) => failCall(result))
      }
    },
    confirmSetup(id) {
      const { paymentWay } = this.$route.query
      this.$loading(true)
      this.$get(
        // `${STRIPE_PREAUTH_AUTO_CONFIRM}`,
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`,
        {},
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (paymentWay === "PAYMENT_SAVED_PROPAY") {
            this.saveCard()
          }
          let jumpUrl = `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
          if (this.payType !== "YJ") {
            jumpUrl = `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=${this.payType}`
          }
          this.$router.replace(jumpUrl)
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    getPayMode() {
      console.log("hahah", this.cabinet, this.wallet)
      this.$loading(true)
      let agentId = ""
      if (this.payType === "CZ") {
        agentId = this.wallet
          ? this.wallet.pDailiId
          : this.cabinet.userWallet.pDailiId
      }
      this.$get(
        this.payType !== "CZ"
          ? `${PAYMODE}${`?id=${this.qrcode || this.$route.query.cabinetID}`}`
          : `${PAYMODE}?agentId=${agentId}`,
        {},
        async (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              } else if (v.pPaymentType === "PAYPAL") {
                v.label = "Paypal"
                v.phone = "all"
              } else if (v.pPaymentType === "STRIPE_KLARNA") {
                v.label = "Klarna"
                v.phone = "all"
              } else if (v.pPaymentType === "MIDTRANS_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "MIDTRANS_GOPAY") {
                v.label = "GOPAY"
              }
              // list.push(v)
            })
            list = list.filter((v) => !!v.label)
            const plist = await this.getUserPayCard()
            if (plist.length) {
              plist.map((item) => list.unshift(item))
            }
            this.payModeList = list

            if (list.length > 0) {
              // let item = list.filter(v => {
              //   return v.pPaymentType === 'STRIPE_CARD'
              // })
              this.payMode = list[0].pPaymentType
              this.currencySymbol = list[0].currencySymbol // 货币符号
              this.currencyname = list[0].configDetails.currency // 货币类型
              this.publicKey = list[0].publicKey || ""
              this.paymentConfig = list[0].paymentConfig
              this.pId = list[0].pId
              this.country = list[0].configDetails.accountCountry
              console.log(this.publicKey, this.payMode)
              if (
                list[0].publicKey &&
                list[0].pPaymentType !== "M-PESA" &&
                list[0].pPaymentType !== "MIDTRANS_CARD" &&
                list[0].pPaymentType !== "MIDTRANS_GOPAY"
              ) {
                this.loadStripeScript(list[0].publicKey)
              }
            }
            console.log("list======", list)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    }
  },
  created() {
    this.Query = this.$route.query
    let { paymentId, pmid } = this.$route.query
    let price = this.$route.query.price
    let formId = this.$route.query.formId
    this.price = price ? Number(price) : 0
    this.orderId = this.$route.query.outTradeNo
    this.formId = formId
    this.payType = this.$route.query.payType
    this.cabinetID = this.$route.query.cabinetID
    this.yaJinType = this.$route.query.yaJinType
    const payId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    )
    console.log(`query-payId:${payId}-paymentId:${paymentId}-pmid:${pmid}`)
    if (paymentId && pmid) {
      this.onConfirmSaveCard(paymentId, pmid)
    } else if (payId) {
      this.confirmSetup(payId)
    }
    this.getPayMode()
    console.log(this)
  }
}
</script>

  <style lang="less">
.noClick {
  opacity: 0.5;
  pointer-events: none;
  /* 禁止鼠标点击 */
}

.confirm-warp {
  overflow-y: scroll;
}

.confirm-info {
  background: #ffffff;
  text-align: center;
  padding: 40px;
}

.confirm-title {
  font-size: 36px;
}

.confirm-price {
  font-size: 80px;
  color: #fc0d1b;
}

.confirm-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  & span {
    text-align: left;
    font-size: 28px;
    color: #999999;
  }
}

.confirm-payment {
  margin: 30px;

  &-label {
    padding: 10px 30px;
    font-size: 46px;
    color: #000;
    font-weight: bold;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 30px 20px;
    border: solid 6px #ddd;
    border-radius: 20px;
    background: #ffffff;
    margin-top: 20px;

    & img {
      width: 40px;
      height: 40px;
    }

    &-info {
      display: flex;
      align-items: center;
      flex: 1;

      & .content {
        padding-left: 10px;
      }

      & .sub_title {
        font-size: 28px;
        color: #29cdf0;
      }
    }
    &-operate {
      display: flex;
      justify-content: flex-end;
      &-text {
        padding: 4px 12px;
        font-size: 28px;
        color: #fc0d1b;
      }
    }
  }
}

.bordermaincolor {
  border-color: #29cdf0;
}

.confirm-remark {
  padding: 0 32px;
}

.confirm-remark .title {
  margin-top: 40px;
  font-size: 32px;
}

.confirm-remark .desc {
  font-size: 28px;
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  word-break: normal !important;
  white-space: normal !important;
}

.confirm-btns {
  padding: 60px 40px 20px;
}

.confirm-btns .btn {
  margin-top: 20px;
}

/* #ifdef MP-WEIXIN */
.confirm-btns button.btn {
  background-color: #65b74e;
}

/* #endif */
.confirm-checkbox {
  padding: 30px;

  & label {
    display: flex;
    align-items: center;
  }

  & span {
    color: #52ac7a;
  }
}

.btn-paypal {
  background: #fec348 !important;
}

.btn-paypal img {
  width: 277px;
  height: 60px;
  margin: 18px 0;
}

.btn-applepay {
  background: #ffffff !important;
  color: #050608;
}

.btn-applepay img {
  width: 143px;
  height: 60px;
  margin: 18px 0;
}

.btn-googlepay {
  color: #5f6368;
  background: #ffffff !important;
}

.btn-googlepay img {
  width: 145px;
  height: 60px;
  margin: 18px 0;
}

.creditcard-input {
  margin: 48px 48px 80px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}

.creditcard-btn {
  margin: 48px;
}

.confirm-row {
  margin: 36px 45px 140px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 4px solid rgb(211, 211, 211);
  border-radius: 20px;

  .area {
    width: 100px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  input {
    font-size: 32px;
    width: 300px;
    flex: 1;
    height: 80px;
    padding: 0 20px;
    margin: 0;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }

  .area-down {
    // margin-left: 10px;
    width: 0;
    height: 0;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: black transparent transparent;
  }

  .login-code {
    width: 180px;
    height: 60px;
    background-image: linear-gradient(
      to right,
      rgba(194, 194, 194, 1),
      rgba(252, 252, 252, 1)
    );

    img {
      width: 180px;
      height: 60px;
    }
  }
}
</style>
