<template>
  <div class="downloaddark">
    <div class="icondark">
      <img src="@/assets/imgs/appstore_dark.png" @click="onAppStore" />
      <img src="@/assets/imgs/googleplay_dark.png" @click="onGooglePlay" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {}
  },
  methods: {
    onAppStore() {
      console.log("11111")
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay() {
      console.log("22222")
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    }
  }
}
</script>

<style lang="less" scoped>
.downloaddark {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0 60px;
  .icondark {
    width: 66%;
    margin: 0 auto;
    img {
      width: 100%;
    }
    img:last-child {
      margin-top: 20px;
    }
  }

  &.login {
    margin-top: 46px;
    .icondark {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      img {
        width: 72%;
      }
    }
  }
}
</style>
